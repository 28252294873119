import React, { FC } from 'react';

import { loaderGif } from 'assets/images';

import { Image } from '../Image';

interface Props {
  className?: string;
}

export const Loader: FC<Props> = ({ className }) => (
  <Image
    url={loaderGif}
    className={className}
  />
);
