// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_buttonIcon__8kLDp {\n  margin: -10px;\n  padding: 10px;\n  border: 0;\n  background: none;\n  outline: 0;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n}", "",{"version":3,"sources":["webpack://./src/components/ButtonIcon/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,aAJwB;EAKxB,SAAA;EACA,gBAAA;EACA,UAAA;EAEA,uBAAA;EACA,mBAAA;EACA,aAAA;AAFF","sourcesContent":["$touchableAreaAroundIcon: 10px;\n\n.buttonIcon {\n  margin: -$touchableAreaAroundIcon;\n  padding: $touchableAreaAroundIcon;\n  border: 0;\n  background: none;\n  outline: 0;\n\n  justify-content: center;\n  align-items: center;\n  display: flex;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonIcon": "styles_buttonIcon__8kLDp"
};
export default ___CSS_LOADER_EXPORT___;
