import React, { FC } from 'react';
import {
  BrowserRouter,
  Routes as RoutesDom,
  Route,
} from 'react-router-dom';

import { PathName } from 'appConstants';
import { HomePage, HousingPage } from 'pages';

const Routes: FC = () => (
  <BrowserRouter>
    <RoutesDom>
      <Route
        path={PathName.Home}
        element={<HomePage />}
      />

      <Route
        path={PathName.Housing}
        element={<HousingPage />}
      >
        <Route
          path={PathName.HousingId}
          element={<HousingPage />}
        />
      </Route>

      <Route
        path={PathName.HousingPhoneId}
        element={<HousingPage />}
      >
        <Route
          path={PathName.HousingId}
          element={<HousingPage />}
        />
      </Route>
    </RoutesDom>
  </BrowserRouter>
);

export { Routes };
