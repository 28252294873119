import { ListState } from 'types';

import { ListActionType } from './actionsTypes';

export const listSetState = (payload: Partial<ListState>) => ({
  type: ListActionType.SetState,
  payload,
});

export const getMap = (payload: boolean) => ({
  type: ListActionType.GET_MAP,
  payload,
});

export const getAllItemInfo = () => ({
  type: ListActionType.GET_ALL_DATA_INFO,
});

export const getItemInfo = (
  payload: { markerId: number | string },
) => ({
  type: ListActionType.GET_ITEM_INFO,
  payload,
});
