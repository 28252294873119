import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export function* callApi(options: {
  baseURL: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  endpoint?: string;
}): SagaIterator {
  const { baseURL } = options;

  const url = `${baseURL}`;

  const response: Response = yield call(fetch, url);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  let json: Record<string, any>;
  try {
    json = yield call([response, response.json]);
  } catch (error) {
    json = {
    };
  }

  return json;
}
