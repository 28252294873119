import { ListState } from 'types';
import { createReducer } from 'utils';

import { mockNotData } from 'appConstants';

import { listHandlers } from './handlers';

export const listInitialState: Readonly<ListState> = {
  isLoadingMap: false,
  fullList: undefined,
  activeItem: mockNotData,
};

export default createReducer(listInitialState, listHandlers);
