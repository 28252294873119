import {
  format,
} from 'date-fns';

export const dateFormat = (date: Date | string | number) => {
  const dateToFormat = new Date(date);
  return format(dateToFormat, 'dd/MM/yyyy');
};

export const isDate = (value: string): boolean => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;

  return dateRegex.test(value) || dateTimeRegex.test(value);
};

// ("+71234567890" || 81234567890) => "+7 (123) 456-78-90"
export const formattedPhoneNumber = (phone: string | number) => {
  const ph = phone.toString();
  if (ph.includes('+7')) {
    return `+${ph.slice(1, 2)} (${ph.slice(2, 5)}) ${ph.slice(5, 8)}-${ph.slice(8, 10)}-${ph.slice(10)}`;
  }
  return `+7 (${ph.slice(1, 4)}) ${ph.slice(4, 7)}-${ph.slice(7, 9)}-${ph.slice(9)}`;
};
