import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { listSelectors } from 'store/list/selectors';
import { getAllItemInfo, getMap } from 'store/list/actionCreators';
import { PathName } from 'appConstants';
import { Loader, MapComponent } from 'components';
import { ItemType } from 'types';

import styles from './styles.module.scss';

export const HomePage: FC = () => {
  const navigate = useNavigate();
  const { fullList, isLoadingMap } = useSelector(listSelectors.getState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMap(true));
    dispatch(getAllItemInfo());
  }, [dispatch]);

  const onClickMarker = useCallback((marker: ItemType) => {
    navigate(`${PathName.Housing}/${marker.yaId}`);
  }, [navigate]);

  const newZoom = localStorage.getItem('newZoom');
  const newCoord = localStorage.getItem('centerMap');

  const defaultZoom = (!!newZoom && newZoom !== 'NaN') ? Number(newZoom) : 13;
  const coordinates = (!!newCoord && newCoord !== 'NaN,NaN') ? newCoord : '55.7522, 37.6156';
  const centerMap = coordinates.split(',').map((string: string) => Number(string));

  return (
    <>
      {isLoadingMap && (
        <div className={styles.loader_container}>
          <Loader className={styles.loader} />
        </div>
      )}
      <div className={styles.home_container}>
        <MapComponent
          defaultZoom={defaultZoom}
          markers={fullList}
          onClick={onClickMarker}
          defaultCenter={centerMap}
        />
      </div>
    </>
  );
};
