import React, { memo } from 'react';

import { phoneIcon, closeIcon } from 'assets/images';
import { PhoneType } from 'types';

import {
  Button,
  ButtonIcon,
  IconText,
} from 'components';

import 'react-slideshow-image/dist/styles.css';
import styles from './styles.module.scss';

interface IconTextProps {
  phone: PhoneType | null;
  onClosePhone: () => void;
  id?: string;
}

export const PopupPhone = memo(({
  phone,
  onClosePhone,
  id,
}: IconTextProps) => (
  <div className={styles.popup}>
    <div className={styles.popup_content}>
      <ButtonIcon
        className={styles.popup_close}
        imageURL={closeIcon}
        onClick={onClosePhone}
      />
      <div className={styles.popup_title}>Контакты для связи</div>
      <a href={`tel:${phone?.value}`}>
        <Button
          isFullWidth
          id={id}
        >
          <IconText
            icon={phoneIcon}
            title={phone?.number || ''}
          />
        </Button>
      </a>
    </div>
  </div>
));
