/* eslint-disable no-console */
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { callApi } from 'api';

import { ItemType } from 'types';
import { mockNotData, PathName } from 'appConstants';

import { ListActionType } from './actionsTypes';
import { getItemInfo, getMap, listSetState } from './actionCreators';
import { listSelectors } from './selectors';

export function* getMapSaga({
  payload,
}: ReturnType<typeof getMap>) {
  try {
    yield put(listSetState({ isLoadingMap: payload }));
  } catch (exception) {
    yield put(listSetState({ isLoadingMap: false }));
  }
}

export function* getAllDataSaga() {
  try {
    const fullList: ItemType[] = yield call(callApi, {
      baseURL: process.env.NODE_ENV === 'development' ? PathName.DevBackend : PathName.ProdBackend,
    });
    yield put(listSetState({ fullList }));
  } catch (exception) {
    console.log(exception);
  }
}

export function* getItemSaga({
  payload: {
    markerId,
  },
}: ReturnType<typeof getItemInfo>) {
  try {
    const fullList: ItemType[] = yield select(listSelectors.getProp('fullList'));
    const listData: ItemType[] = yield fullList || call(callApi, {
      baseURL: process.env.NODE_ENV === 'development' ? PathName.DevBackend : PathName.ProdBackend,
    });

    const activeItem = listData.find(({ yaId }) => yaId === Number(markerId)) || mockNotData;

    yield put(listSetState({
      activeItem,
    }));
  } catch (exception) {
    console.log(exception);
  }
}

export const listEffects = [
  takeLatest(ListActionType.GET_MAP, getMapSaga),
  takeLatest(ListActionType.GET_ALL_DATA_INFO, getAllDataSaga),
  takeLatest(ListActionType.GET_ITEM_INFO, getItemSaga),
];
