import React, { memo } from 'react';
import cx from 'classnames';

import { Loader } from '../Loader';

import { ButtonProps } from './types';

import styles from './styles.module.scss';

export const Button = memo((
  {
    isFullWidth,
    onClick,
    className,
    children,
    disabled,
    isLoading,
    id,
  }: ButtonProps,
) => (
  <button
    id={id}
    type="button"
    className={cx(
      styles.button,
      {
        [styles.full_width]: isFullWidth,
        [styles.disabled]: disabled,
      },
      className,
    )}
    onClick={onClick}
    disabled={disabled || isLoading}
  >
    <div className={cx(
      { [styles.children_while_loading]: isLoading },
    )}
    >
      {children}
    </div>
    { isLoading && <Loader className={styles.loader} /> }
  </button>
));
