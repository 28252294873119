import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Slide } from 'react-slideshow-image';

import { arrowBackIcon, phoneIcon, walkerIcon } from 'assets/images';
import { PathName } from 'appConstants';
import { MetroType, PhoneType, PriceType } from 'types';
import { formattedPhoneNumber } from 'utils';
import { getItemInfo } from 'store/list/actionCreators';
import { listSelectors } from 'store/list/selectors';

import { PopupPhone } from 'containers';
import {
  Button,
  ButtonIcon,
  IconText,
  Image,
} from 'components';

import 'react-slideshow-image/dist/styles.css';
import './style.css';
import styles from './styles.module.scss';

export const HousingPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeItem } = useSelector(listSelectors.getState);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const id = pathname.match(/\d+/)[0] || '';

    const phone = pathname.includes(PathName.HousingPhoneId);
    setIsOpen(phone);
    dispatch(getItemInfo({ markerId: id }));
  }, [dispatch, pathname]);

  const onClickBack = useCallback(() => {
    navigate(PathName.Home);
  }, [navigate]);

  const indicators = useCallback((index?: number) => {
    if (!activeItem.foto) {
      return null;
    }
    const photoArr = JSON.parse(activeItem.foto);
    return (
      <div className="indicator">
        {(index || 0) + 1}
        /
        {photoArr.length}
      </div>
    );
  }, [activeItem]);

  const properties = {
    prevArrow: <span />,
    nextArrow: <span />,
    autoplay: true,
    indicators,
  };

  const metroDistance = useMemo(() => {
    if (!activeItem.metro) {
      return null;
    }
    const str: string = activeItem.metro;
    const data: MetroType[] = JSON.parse(str);
    const regExp = /\*|�|\$/g;

    return (
      data?.map((metro) => (
        <div
          className={styles.housing_metro}
          key={metro.id}
        >
          <div
            style={{ backgroundColor: metro?.color }}
            className={styles.housing_metro_ellipse}
          />
          {metro?.name}
          <div className={styles.housing_metro_distance}>
            <Image url={walkerIcon} />
            {metro?.distance.replace(regExp, ' ')}
          </div>
        </div>
      ))
    );
  }, [activeItem]);

  const priceDay = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getPriceList = (obj: any): PriceType[] | null => {
      const priceList: PriceType[] = [];
      let i = 1;
      while (obj[`price_${i}`] || obj[`price_description_${i}`]) {
        priceList.push({
          price: obj[`price_${i}`] || null,
          price_description: obj[`price_description_${i}`] || null,
          id: i,
        });
        i += 1;
      }
      return priceList.length ? priceList : null;
    };

    const priceList = getPriceList(activeItem);

    return (
      priceList && priceList.map((place) => (
        <div
          key={place.id}
          className={styles.housing_place}
        >
          {place.price_description && <div>{place.price_description}</div>}
          {place.price && (
            <div className={styles.housing_place_price}>
              {place.price}
            </div>
          )}
        </div>
      ))
    );
  }, [activeItem]);

  const photos = useMemo(() => {
    if (!activeItem.foto) {
      return null;
    }
    const photoArr = JSON.parse(activeItem.foto);
    return (
      photoArr.map((img: string) => (
        <div
          key={img}
          className="each-slide-effect"
        >
          <div style={{ backgroundImage: `url(${img})` }} />
        </div>
      ))
    );
  }, [activeItem]);

  const phoneData: PhoneType | null = useMemo(() => {
    if (!activeItem.phone) {
      return null;
    }
    return {
      value: activeItem.phone,
      number: formattedPhoneNumber(activeItem.phone),
    };
  }, [activeItem]);

  const onOpenPhone = useCallback(() => {
    navigate(`${PathName.HousingPhoneId}/${activeItem.yaId}`);
    setIsOpen(true);
  }, [setIsOpen, activeItem, navigate]);

  const onClosePhone = useCallback(() => {
    navigate(-1);
    setIsOpen(false);
  }, [setIsOpen, navigate]);

  return (
    <div className={styles.housing_component}>
      <div className={styles.housing_container}>
        <ButtonIcon
          onClick={onClickBack}
          imageURL={arrowBackIcon}
          className={styles.housing_back}
        />
        <div className={styles.housing_title}>{activeItem?.title}</div>
        <div className={styles.housing_gray_line} />
        <div className={styles.housing_address}>
          {activeItem?.address}
        </div>
        {metroDistance && (
          <div className={styles.housing_metros}>
            {metroDistance}
          </div>
        )}
        {activeItem?.description && (
          <>
            <div className={styles.housing_subtitle}>
              Описание
            </div>
            <div className={styles.housing_description}>
              {activeItem?.description}
            </div>
          </>
        )}
      </div>

      {priceDay && (
        <div className={styles.housing_price_container}>
          <div className={styles.housing_subtitle}>
            Цены
          </div>
          <div className={styles.housing_price}>
            {priceDay}
          </div>
        </div>
      )}

      {photos && (
        <div className={styles.housing_photos_container}>
          <div className={styles.housing_subtitle}>
            Фото
          </div>
          <div className={styles.housing_photos}>
            <Slide {...properties}>
              {photos}
            </Slide>
          </div>
        </div>
      )}

      <div className={styles.housing_photos_container}>
        <Button
          onClick={onOpenPhone}
          isFullWidth
          disabled={!phoneData}
          id="btn_contact_phone_estate"
        >
          <IconText
            icon={phoneIcon}
            title={phoneData ? 'Связаться' : 'Номер не указан'}
          />
        </Button>
        {isOpen && (
          <PopupPhone
            phone={phoneData}
            onClosePhone={onClosePhone}
            id="btn_show_contacts_estate"
          />
        )}
      </div>
    </div>
  );
};
