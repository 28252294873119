import React, {
  FC,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  YMaps,
  Map,
  Placemark,
} from '@pbe/react-yandex-maps';

import { ItemType } from 'types';
import {
  geolocationIcon,
  markerIcon,
  myLocationIcon,
  minusIcon,
  plusIcon,
} from 'assets/images';
import { getMap } from 'store/list/actionCreators';

import { ButtonIcon } from 'components';

import './style.css';
import styles from './styles.module.scss';

interface MapProps {
  defaultCenter: number[];
  defaultZoom: number;
  markers?: ItemType[];
  onClick: (data: ItemType) => void;
}

export const MapComponent: FC<MapProps> = ({
  defaultCenter,
  defaultZoom,
  markers,
  onClick,
}) => {
  const map = useRef(null);
  const dispatch = useDispatch();
  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);
  const [userLocation, setUserLocation] = useState<number[] | null>(null);

  const loaderChange = useCallback((isLoading: boolean) => {
    dispatch(getMap(isLoading));
  }, [dispatch]);

  const zoomChange = useCallback((z: number) => {
    setZoom(z);
    if (map.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      map.current.setZoom(z, { duration: 300 });
    }
  }, [setZoom]);

  const centerChange = useCallback((cent: number[]) => {
    setCenter(cent);
    if (map.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      map.current.panTo(cent, { duration: 300 });
    }
  }, [setCenter]);

  const getLocation = useCallback(() => {
    if (!userLocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation([latitude, longitude]);
      });
    } else if (center !== userLocation) {
      const zoomOutTime = setTimeout(() => zoomChange(7), 400);
      const centeringTime = setTimeout(() => centerChange(userLocation), 800);
      const zoomInTime = setTimeout(() => zoomChange(13), 1200);
      return () => {
        clearTimeout(zoomOutTime);
        clearTimeout(centeringTime);
        clearTimeout(zoomInTime);
      };
    }
  }, [
    center,
    setUserLocation,
    centerChange,
    zoomChange,
    userLocation,
  ]);

  const handleMyLocationClick = useCallback(() => {
    if (!userLocation) {
      getLocation();
      return;
    }
    centerChange(userLocation);
  }, [centerChange, getLocation, userLocation]);

  const handleMapClick = useCallback((event: { get: (arg0: string) => number[]; }) => {
    const newCenter = event.get('newCenter');
    const newCenterString = newCenter.join();
    localStorage.setItem('centerMap', newCenterString);
    centerChange(newCenter);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newZoom: number = event.get('newZoom');

    localStorage.setItem('newZoom', newZoom.toString());
    zoomChange(newZoom);
  }, [centerChange, zoomChange]);

  return (
    <div className={styles.map_container}>
      <YMaps
        key="07156ef4-f03f-4f7c-9a08-d6af9383ef60"
        query={{
          lang: 'ru_RU',
          apikey: '07156ef4-f03f-4f7c-9a08-d6af9383ef60',
        }}
      >
        <Map
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          instanceRef={map}
          defaultState={{ center, zoom, behaviors: ['default', 'scrollZoom'] }}
          className={styles.map}
          onBoundsChange={handleMapClick}
          onLoad={(e) => loaderChange(!e)}
        >
          {markers && markers?.length > 0 ? markers.map((marker) => (
            <Placemark
              key={marker.yaId}
              geometry={[marker.coordinates[1], marker.coordinates[0]]}
              onClick={() => onClick(marker)}
              options={{
                iconLayout: 'default#image',
                iconImageSize: [50, 50],
                iconImageHref: markerIcon,
              }}
            />
          )) : null}
          {userLocation && (
            <Placemark
              geometry={userLocation}
              options={{
                iconLayout: 'default#image',
                iconImageSize: [50, 50],
                iconImageHref: myLocationIcon,
              }}
            />
          )}

          <div className={styles.map_control}>
            <ButtonIcon
              onClick={() => zoomChange(zoom + 1)}
              imageURL={plusIcon}
              className={styles.map_control_icon}
            />
            <ButtonIcon
              onClick={() => zoomChange(zoom - 1)}
              imageURL={minusIcon}
              className={styles.map_control_icon}
            />
            <ButtonIcon
              onClick={handleMyLocationClick}
              imageURL={geolocationIcon}
              className={styles.map_control_icon_geo}
            />
          </div>
        </Map>
      </YMaps>
    </div>
  );
};
