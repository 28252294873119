// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --color-white: #ffffff;\n  --color-black: #03050A;\n}\n\n.styles_home_container__RwAxb {\n  width: 100%;\n  height: 100%;\n}\n\n.styles_loader_container__6HpYo {\n  text-align: center;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.styles_loader__97hOX {\n  width: 75px;\n  height: 75px;\n  margin: 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/assets/styles/_palette.scss","webpack://./src/pages/HomePage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,sBAAA;ACCF;;AADA;EACE,WAAA;EACA,YAAA;AAIF;;AADA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAIF;;AADA;EACE,WAAA;EACA,YAAA;EACA,cAAA;AAIF","sourcesContent":[":root {\n  --color-white: #ffffff;\n  --color-black: #03050A;\n}\n\n$IBMPS: 'IBM Plex Sans', sans-serif;\n$shadow: rgba(0, 0, 0, 0.6);\n\n$textColor: #252934;\n\n$white: #FFFFFF;\n$white1: #F4F4F5;\n\n$gray: #DFE0E1;\n$gray2: #9B9B9B;\n\n$green: #07B852;\n$green1: #0bb050;\n$green2: #0ba049;\n$green3: #0b9143;\n\n$black: #03050A;\n\n\n","@import 'src/assets/styles/palette';\n\n.home_container {\n  width: 100%;\n  height: 100%;\n}\n\n.loader_container {\n  text-align: center;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.loader {\n  width: 75px;\n  height: 75px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home_container": "styles_home_container__RwAxb",
	"loader_container": "styles_loader_container__6HpYo",
	"loader": "styles_loader__97hOX"
};
export default ___CSS_LOADER_EXPORT___;
