// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --color-white: #ffffff;\n  --color-black: #03050A;\n}\n\n.styles_icon_text_container__RK1wx {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.styles_icon_text_title__-d9rh {\n  font-family: \"IBM Plex Sans\", sans-serif;\n  font-style: normal;\n  font-size: 14px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/assets/styles/_palette.scss","webpack://./src/components/IconText/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,sBAAA;ACCF;;AAAA;EACE,aAAA;EACA,mBAAA;EAEA,SAAA;AAEF;;AACA;EACE,wCDNM;ECON,kBAAA;EACA,eAAA;EACA,gBAAA;AAEF","sourcesContent":[":root {\n  --color-white: #ffffff;\n  --color-black: #03050A;\n}\n\n$IBMPS: 'IBM Plex Sans', sans-serif;\n$shadow: rgba(0, 0, 0, 0.6);\n\n$textColor: #252934;\n\n$white: #FFFFFF;\n$white1: #F4F4F5;\n\n$gray: #DFE0E1;\n$gray2: #9B9B9B;\n\n$green: #07B852;\n$green1: #0bb050;\n$green2: #0ba049;\n$green3: #0b9143;\n\n$black: #03050A;\n\n\n","@import 'src/assets/styles/palette';\n\n\n.icon_text_container {\n  display: flex;\n  align-items: center;\n\n  gap: 10px\n}\n\n.icon_text_title {\n  font-family: $IBMPS;\n  font-style: normal;\n  font-size: 14px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon_text_container": "styles_icon_text_container__RK1wx",
	"icon_text_title": "styles_icon_text_title__-d9rh"
};
export default ___CSS_LOADER_EXPORT___;
