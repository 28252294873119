import React, { memo } from 'react';
import cx from 'classnames';

import { Image } from 'components';

import styles from './styles.module.scss';

interface IconTextProps {
  classNameContainer?: string;
  classNameImage?: string;
  classNameText?: string;
  icon?: string;
  title: string;
}

export const IconText = memo(({
  classNameContainer,
  classNameImage,
  classNameText,
  icon,
  title,
}: IconTextProps) => (
  <div className={cx(styles.icon_text_container, classNameContainer)}>
    {!!icon && (
    <Image
      className={cx(styles.icon_text_image, classNameImage)}
      url={icon}
    />
    )}
    <div className={cx(styles.icon_text_title, classNameText)}>
      {title}
    </div>
  </div>
));
